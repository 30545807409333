import axios from "@/utils/request";
export async function getList(params) {
    const res = await axios({
        method: 'get',
        url: 'https://api.demondirectory.com/popularization/page',
        params
        // params: {
            // type: 'SportsEventsWiki',
            // pageNum: 1,
            // pageSize: 30
        // },
    })
    return res.data
}
export async function detail(params) {
    const res = await axios({
        method: 'get',
        url: 'https://api.demondirectory.com/popularization/detail',
        params
    })
    return res.data
}
